import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/news.tsx";
import { TwitterTweetEmbed } from 'react-twitter-embed';
export const pageTitle = "Armeria Newsletter vol. 2";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Mailchimp = makeShortcode("Mailchimp");
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "armeria-newsletter-vol-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#armeria-newsletter-vol-2",
        "aria-label": "armeria newsletter vol 2 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Armeria Newsletter vol. 2`}</h1>
    <p {...{
      "className": "date"
    }}>{`2nd February 2021`}</p>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#from-the-devs"
        }}>{`From the devs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#from-the-community"
        }}>{`From the community`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#we-need-your-comments"
        }}>{`We need your comments`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#good-first-issues"
        }}>{`Good first issues`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#stay-tuned"
        }}>{`Stay tuned`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#thank-you"
        }}>{`Thank you!`}</a></li>
    </ul>
    <video {...{
      "className": "hideOnReducedMotion",
      "src": "/bf04f0ae6df78db5375887fb6f8e2f78/armeria.m4v",
      "preload": "none",
      "autoPlay": true,
      "muted": true,
      "loop": true,
      "style": {
        "width": "282px",
        "height": "112px"
      }
    }}>{`
  `}<img parentName="video" {...{
        "src": "/2cbd433cd318026a1c3017f022dbc263/armeria.gif",
        "loading": "lazy",
        "width": 282,
        "height": 112
      }}></img>
    </video>
    <h2 {...{
      "id": "from-the-devs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#from-the-devs",
        "aria-label": "from the devs permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`From the devs`}</h2>
    <p>{`✨ `}<a parentName="p" {...{
        "href": "/release-notes/1.4.0/"
      }}>{`Armeria 1.4.0`}</a>{` has been released.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://consul.io/"
        }}>{`Consul`}</a>{` service discovery support`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/docs/server-service-registration/#consul-based-service-registration-with-consulupdatinglistener"
            }}>{`Consul Registration for Server`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/docs/client-service-discovery/#consul-based-service-discovery-with-consulendpointgroup"
            }}>{`Consul Service Discovery for Client`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`Protect a fresh node from getting too much traffic suddenly by smoothly ramping up the weight of
`}<a parentName="li" {...{
          "href": "typeplural://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
        }}>{`typeplural://Endpoint`}</a>{` in an `}<a parentName="li" {...{
          "href": "type://EndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointGroup.html"
        }}>{`type://EndpointGroup`}</a>{` using `}<a parentName="li" {...{
          "href": "type://EndpointSelectionStrategy#rampingUp():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointSelectionStrategy.html#rampingUp()"
        }}>{`type://EndpointSelectionStrategy#rampingUp()`}</a>{`.`}</li>
      <li parentName="ul">{`Ability to close a connection after handling a certain number of requests or after a certain period of time,
rather than keeping it open indefinitely.`}</li>
    </ul>
    <p>{`🏆 We nominated contributors for a reward!`}</p>
    <ul>
      <li parentName="ul">{`From the last contributor reward in April 2019 to Armeria 1.3.0 release in December 2020,
Armeria got 63 contributors. `}</li>
      <li parentName="ul">{`Surprisingly, 52 of them were first-time contributors. `}</li>
      <li parentName="ul">{`As a token of appreciation, we've sent a thank you note with a gift. `}</li>
      <li parentName="ul">{`Thanks all for participating with us, and we're looking forward to seeing you keep it up with this year. 😄`}
        <div style={{
          "width": "100%",
          "display": "flex",
          "flexWrap": "wrap"
        }}>
  <div className="tweet-box" style={{
            "marginRight": "1rem"
          }}>
    <TwitterTweetEmbed tweetId={'1337766539328712706'} mdxType="TwitterTweetEmbed" />
  </div>
  <div className="tweet-box">
    <TwitterTweetEmbed tweetId={'1337330083330215938'} mdxType="TwitterTweetEmbed" />
  </div>
        </div>
      </li>
    </ul>
    <h2 {...{
      "id": "from-the-community",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#from-the-community",
        "aria-label": "from the community permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`From the community`}</h2>
    <p>{`📝 `}<a parentName="p" {...{
        "href": "https://doordash.engineering/2021/01/12/building-a-grpc-client-standard-with-open-source/"
      }}>{`Building a gRPC Client Standard with Open Source to Boost Reliability and Velocity`}</a>{` | by `}<a parentName="p" {...{
        "href": "https://github.com/haithamgabr"
      }}><strong parentName="a">{`@haithamgabr`}</strong></a></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://doordash.engineering/"
        }}>{`Doordash engineering`}</a>{` has adopted Armeria as a gRPC client, using the features
like DNS service discovery, client-side load balancing, and circuit breaker.`}</li>
    </ul>
    <p>{`🎤 `}<a parentName="p" {...{
        "href": "https://spring.io/blog/2021/01/07/a-bootiful-podcast-netty-and-armeria-founder-trustin-lee"
      }}>{`A Bootiful Podcast: Netty and Armeria founder Trustin Lee`}</a>{` | by `}<a parentName="p" {...{
        "href": "https://github.com/trustin"
      }}><strong parentName="a">{`@trustin`}</strong></a>{` and `}<a parentName="p" {...{
        "href": "https://github.com/joshlong"
      }}><strong parentName="a">{`@joshlong`}</strong></a></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/joshlong"
        }}><strong parentName="a">{`@joshlong`}</strong></a>{` interviews Netty and Armeria founder `}<a parentName="li" {...{
          "href": "https://github.com/trustin"
        }}><strong parentName="a">{`@trustin`}</strong></a>{`. As you may notice from this episode,
Trustin is now mainly working on Armeria from `}<a parentName="li" {...{
          "href": "https://databricks.com/"
        }}>{`Databricks`}</a>{`.`}</li>
    </ul>
    <p>{`🚀 `}<a parentName="p" {...{
        "href": "https://github.com/http4s/http4s-armeria"
      }}>{`http4s-armeria 0.1.0 released`}</a></p>
    <ul>
      <li parentName="ul">{`Good news for Scala users; you can now use Armeria as a backend for `}<a parentName="li" {...{
          "href": "https://http4s.org/"
        }}>{`http4s`}</a>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "we-need-your-comments",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#we-need-your-comments",
        "aria-label": "we need your comments permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`We need your comments`}</h2>
    <p>{`🤔 Your opinion means a lot to us. Please let us know what you think about these proposals:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3051"
        }}>{`#3051`}</a>{` Use context for when the subscriber is created by Reactor, not Publisher`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3155"
        }}>{`#3155`}</a>{` Unify gRPC timeout scheduler between gRPC server and client.`}</li>
    </ul>
    <h2 {...{
      "id": "good-first-issues",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#good-first-issues",
        "aria-label": "good first issues permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Good first issues`}</h2>
    <p>{`Want to contribute but not sure where to start from? Try one of these:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3315"
        }}>{`#3315`}</a>{` Programmatically create a blocking task executor with a custom thread size,
perhaps by adding a new builder method to `}<a parentName="li" {...{
          "href": "type://ServerBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html"
        }}>{`type://ServerBuilder`}</a>{` that takes the number of threads.`}</li>
    </ul>
    <h2 {...{
      "id": "stay-tuned",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#stay-tuned",
        "aria-label": "stay tuned permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Stay tuned`}</h2>
    <p>{`Sign up for our newsletters to keep up-to-date with new releases and useful tips!`}</p>
    <div style={{
      "maxWidth": "320px",
      "marginTop": "0.5rem"
    }}>
  <Mailchimp mdxType="Mailchimp" />
    </div>
    <h2 {...{
      "id": "thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#thank-you",
        "aria-label": "thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Thank you!`}</h2>
    <ThankYou message={() => <p>Since our last newsletter, we received awesome contributions from the following contributors:</p>} usernames={['aanrii', 'anuraaga', 'arhont375', 'cj848', 'cnabro', 'codefromthecrypt', 'delegacy', 'eisig', 'eonezhang', 'eugene70', 'eunchan-kim', 'gary-lo', 'ghkim3221', 'haithamgabr', 'heowc', 'hexoul', 'ikhoon', 'imasahiro', 'jongmin92', 'joonhaeng', 'jorgheymans', 'jrhee17', 'JunoJunho', 'KarboniteKream', 'kojilin', 'kowshikn', 'ks-kim', 'm50d', 'masonshin', 'matsumana', 'mauhiz', 'max904-github', 'minwoox', 'okue', 'perlun', 'policeman-kh', 'progresivoJS', 'renaudb', 'richieyan', 'rickyrattlesnake', 'rolandblain', 'smax48', 'snaiper80', 'syleeeee', 'techno', 'TimurKasatkin', 'tobias-', 'trustin', 'tumile', 'Ubehebe', 'wickedev', 'windmeup', 'wooyeong', 'xmeng1']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      